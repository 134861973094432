import React from "react";
import { BrowserRouter, Routes as AppRoutes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserRoutes from "./Routes/UserRoutes";
import AppLayout from "../layouts/AppLayout";
import LandingPage from "../../components/landing";
import { themeColors } from "../../configs/Colors";

function Router() {
  const routes = [...UserRoutes];
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <div className={`h-[2.5vh] ${themeColors.primary}`}></div>
        <div className="h-[2.5vh] bg-white"></div>
        <div className={`h-[2.5vh] ${themeColors.secondary}`}></div>
        <AppRoutes>
          {routes.map((route) => (
            <Route
              path={route.path}
              element={<AppLayout>{route.component}</AppLayout>}
            />
          ))}
          <Route
            path="*"
            element={
              <AppLayout>
                <LandingPage />
              </AppLayout>
            }
          />
        </AppRoutes>
        <div className={`h-[2.5vh] ${themeColors.secondary}`}></div>
        <div className="h-[2.5vh] bg-white"></div>
        <div className={`h-[2.5vh] ${themeColors.primary}`}></div>
      </BrowserRouter>
    </>
  );
}

export default Router;
