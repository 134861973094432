import React, { useState, useEffect } from "react";
import Roullete from "../spin/Roullete";
import CreateWin from "../win/CreateWin";
import Home from "../home/Home";
import { useLocation } from "react-router-dom";
import { customInterceptors } from "../../services/config";
import { EndPoints } from "../../services/apiEndPoints";
import authService from "../../services/authServices";
import { applyStylesToWheelData } from "../helper/formatWheel";
import { errorCode } from "../../configs/ErrorMessage";
import { toast } from "react-toastify";

function LandingPage() {
  const [showPrize, setShowPrize] = useState(false);
  const [spokes, setSpokes] = useState([]);
  const [wonPrize, setWonPrize] = useState();
  const API = customInterceptors();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [hasToken, setHasToken] = useState(false);
  const redirectToSpinTerms=()=>{
    window.open("https://www.lottosonline.com/spin-to-win-terms-and-conditions")
  }
  const redirectToLottosTerms=()=>{
    window.open("https://www.lottosonline.com/privacy-policy")
  }
  const goToSpin = async () => {
    try {
      const formData = new FormData();
      const decodedUrl = decodeURIComponent(token);
      formData.append("token", decodedUrl);
      const res = await API.post(EndPoints.GET_WHEEL, formData);
      if (res?.data?.status === "1") {
        if (res?.data?.data?.length) {
          const formattedspokes = applyStylesToWheelData(res.data.data);
          setSpokes(formattedspokes);
        } else {
          toast.error(errorCode.GENERAL);
        }
      } else {
        res?.data?.data
          ? toast.error(res.data.data)
          : toast.error(errorCode.GENERAL);
      }
    } catch (error) {
      toast.error(errorCode.GENERAL);
      return null;
    }
  };
  useEffect(() => {
    authService.clearToken();
  }, []);
  useEffect(() => {
    if (token) {
      setHasToken(true);
    }
  }, [token]);


  return showPrize ? (
    <CreateWin spokes={spokes} wonPrize={wonPrize} />
  ) : spokes.length ? (
    <Roullete
      setWonPrize={setWonPrize}
      token={token}
      wonPrize={wonPrize}
      spokes={spokes}
      setShowPrize={setShowPrize}
    />
  ) : (
    <Home redirectToLottosTerms={redirectToLottosTerms} redirectToSpinTerms={redirectToSpinTerms} goToSpin={goToSpin} hasToken={hasToken} />
  );
}

export default LandingPage;
