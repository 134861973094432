export const themeColors={
    secondary:"bg-[#e7a000]",
    primary:"bg-[#582079]",
}
export const themeTextColors={
    secondary:"text-[#e7a000]",
    primary:"text-[#582079]",
}
export const themeColorsWheel={
    secondary:"#e7a000",
    primary:"#582079",
    tertiary:"#CB0B2E",
}