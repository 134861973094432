import React from "react";
import confettiMob from "../../assets/images/confetti.gif";
import { arrangePrizeDescription } from "../helper/FormatData";
import { themeTextColors } from "../../configs/Colors";

function CreateWin({ wonPrize, spokes }) {
  const prize = spokes[wonPrize];
  const noPrizeWon = prize.lo_wheel_odds_id == 95;
  const prizeDescription = prize.option;
  return prizeDescription ? (
    <>
      <div>
        {noPrizeWon ? (
          ""
        ) : (
          <div className="absolute h-[100%] overflow-hidden opacity-40 -z-10">
            <img src={confettiMob} alt="confetti-I"></img>
          </div>
        )}
        <p className="text-2xl p-2 text-white tracking-widest lg:tracking-[0.3em] flex justify-center mt-2 ">
          LOTTOS ONLINE
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          TRY YOUR LUCK
        </p>
        <p
          className={`text-[3.35rem] leading-[1] tracking-widest mt-1 lg:text-[4.10rem] font-[futura] ${themeTextColors.secondary} `}
        >
          <span className="text-[#02154E]">*</span>
          LETS SPIN
          <sup className="text-3xl lg:text-4xl">*</sup>
        </p>
      </div>
      <div className="px-5  ">
        <div className="flex justify-center">
          <hr className="border-t-2 w-[90%] lg:w-[60%] " />
        </div>
        <div className="grid text-center  py-2 place-content-center">
          {noPrizeWon ? (
            ""
          ) : (
            <>
              <p className="text-3xl lg:text-4xl font-[futura-bold] tracking-wide    text-white">
                CONGRATULATIONS!
              </p>
              <p
                className={`${themeTextColors.secondary} font-[futura-bold] text-2xl mt-4`}
              >
                You've Just Won A
              </p>
            </>
          )}

          <div className="grid grid-col-1 gap-x-2 place-content-center">
            <p className="text-5xl  font-[futura-bold] lg:text-6xl  text-white">
              {arrangePrizeDescription(prizeDescription, "title")}{" "}
            </p>
            <div className=" font-[futura-bold] leading-7 text-2xl lg:text-3xl text-white  place-content-start">
              <span>
                {arrangePrizeDescription(prizeDescription, "subtitle")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <hr className="border-t-2 w-[90%] lg:w-[60%] " />
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default CreateWin;
