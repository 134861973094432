import axios from 'axios';

export function customInterceptors() {
  const API = axios.create({ 
    baseURL: process.env.REACT_APP_ENDPOINT_URL,
  });
  API.interceptors.request.use((config) => {
    const authToken ="Basic "+'ZmFtY29tOmZhbWNvbTEyMw==';
    config.headers['Authorization'] = authToken;
    return config;
  });

  return API;
}