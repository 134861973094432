import { useState } from "react";
import wheelImage_en from "../../assets/images/displayWheel-new.png";
import { themeTextColors } from "../../configs/Colors";
import wheelImage from "../../assets/images/wheel-static.png";
import wheelAnimation from "../../assets/images/wheel-animation.gif";

function Home({ goToSpin,redirectToSpinTerms,redirectToLottosTerms, hasToken }) {
  const continueSpin = () => {
    hasToken && goToSpin();
  };
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false);
  const handleAnimationLoaded = () => {
    setIsAnimationLoaded(true);
  };
  return (
    <>
      <div>
        <p className="text-2xl p-2 font- text-white tracking-widest lg:tracking-[0.3em] font-semibold flex justify-center mt-2 ">
          LOTTOS ONLINE
        </p>
        <p className="text-5xl font-[futura-bold] mt-8 lg:text-6xl text-white">
          TRY YOUR LUCK
        </p>
        <p
          className={`text-[3.35rem] leading-[1] tracking-widest mt-1 lg:text-[4.10rem] font-[futura] ${themeTextColors.secondary} `}
        >
          <span className="text-[#02154E]">*</span>
          LETS SPIN
          <sup className="text-3xl lg:text-4xl">*</sup>
        </p>
      </div>

      <div className="grid justify-center ">
        <img
          className={`h-64 md:h-96 mt-6 ${hasToken && "cursor-pointer"}   ${
            isAnimationLoaded ? "block" : "hidden"
          }`}
          onLoad={handleAnimationLoaded}
          src={wheelAnimation}
          onClick={continueSpin}
          alt="wheel-II"
        />

        <img
          className={`mt-6 ${hasToken && "cursor-pointer"} h-64 md:h-96  ${
            isAnimationLoaded ? "hidden" : "block"
          } `}
          src={wheelImage}
          onClick={continueSpin}
          alt="wheel-I"
        />
      </div>

      <div>
        <button
          onClick={goToSpin}
          disabled={!hasToken}
          className={`text-lg font-[futura-bold] px-10  py-2 lg:text-2xl my-4 ${
            !hasToken && "invisible"
          }  text-[#02154E] bg-slate-100  font-extrabold`}
        >
          ENTER
        </button>
      </div>
      <div className=" mx-3">
        <p
          className={`text-lg font-[futura] px-2 h-auto   lg:text-xl my-4 py-1    text-white  `}
        >
          For Terms and Conditions specific to the Free Spins offer, <span onClick={redirectToSpinTerms} className="underline cursor-pointer">click here</span>. To view the LottosOnline.com Privacy Policy, <span onClick={redirectToLottosTerms} className="underline cursor-pointer">click here</span>.
        </p>
      </div>
    </>
  );
}

export default Home;
