import { themeColorsWheel } from "../../../configs/Colors";
import SPOKE1 from "../../../assets/images/prize-images/spoke1.png";
import SPOKE2 from "../../../assets/images/prize-images/spoke2.png";
import SPOKE3 from "../../../assets/images/prize-images/spoke3.png";
import SPOKE4 from "../../../assets/images/prize-images/spoke4.png";
import SPOKE5 from "../../../assets/images/prize-images/spoke5.png";
import SPOKE6 from "../../../assets/images/prize-images/spoke6.png";
import SPOKE7 from "../../../assets/images/prize-images/spoke7.png";
import SPOKE8 from "../../../assets/images/prize-images/spoke8.png";
import SPOKE9 from "../../../assets/images/prize-images/spoke9.png";
import SPOKE10 from "../../../assets/images/prize-images/spoke10.png";
import SPOKE11 from "../../../assets/images/prize-images/spoke11.png";
import SPOKE12 from "../../../assets/images/prize-images/spoke12.png";
import SPOKE13 from "../../../assets/images/prize-images/spoke13.png";
import SPOKE14 from "../../../assets/images/prize-images/spoke14.png";
import SPOKE15 from "../../../assets/images/prize-images/spoke15.png";
import SPOKE16 from "../../../assets/images/prize-images/spoke16.png";
import SPOKE17 from "../../../assets/images/prize-images/spoke17.png";
import SPOKE18 from "../../../assets/images/prize-images/spoke18.png";
import SPOKE19 from "../../../assets/images/prize-images/spoke19.png";
// Function to apply alternating styles to each array item

const imageMapper = {
  3: SPOKE5,
  6: SPOKE4,
  9: SPOKE6,
  12: SPOKE3,
  15: SPOKE2,
  18: SPOKE1,
  19: SPOKE5,
  22: SPOKE4,
  25: SPOKE10,
  28: SPOKE6,
  31: SPOKE15,
  34: SPOKE14,
  37: SPOKE13,
  40: SPOKE2,
  43: SPOKE7,
  46: SPOKE1,
  47: SPOKE5,
  50: SPOKE4,
  53: SPOKE10,
  56: SPOKE6,
  59: SPOKE16,
  62: SPOKE15,
  65: SPOKE14,
  68: SPOKE13,
  71: SPOKE3,
  74: SPOKE12,
  77: SPOKE11,
  80: SPOKE8,
  83: SPOKE9,
  86: SPOKE2,
  89: SPOKE7,
  92: SPOKE1,
  95: SPOKE19,
  // 1: SPOKE5,
  // 2: SPOKE4,
  // 3: SPOKE6,
  // 4: SPOKE17,
  // 5: SPOKE2,
  // 6: SPOKE1,
  // 7: SPOKE5,
  // 8: SPOKE4,
  // 9: SPOKE10,
  // 10: SPOKE6,
  // 11: SPOKE15,
  // 12: SPOKE14,
  // 13: SPOKE18,
  // 14: SPOKE2,
  // 15: SPOKE7,
  // 16: SPOKE1,
  // 17: SPOKE5,
  // 18: SPOKE4,
  // 19: SPOKE10,
  // 20: SPOKE6,
  // 21: SPOKE16,
  // 22: SPOKE15,
  // 23: SPOKE17,
  // 24: SPOKE2,
  // 25: SPOKE7,
  // 26: SPOKE1,
};
export const applyStylesToWheelData = (array) => {
  return array.map((item, index) => {
    let backgroundColor = "";
    const themeColors = [
      themeColorsWheel.primary,
      themeColorsWheel.secondary,
      themeColorsWheel.tertiary,
    ];
    if (array.length % 2 === 0) {
      const isEven = index % 2 === 0;
      backgroundColor = isEven
        ? themeColorsWheel.secondary
        : themeColorsWheel.primary;
    } else {
      const colorIndex = index % themeColors.length;
      backgroundColor = themeColors[colorIndex];
      if (index === array.length - 1 && backgroundColor === themeColors[0]) {
        backgroundColor = themeColors[(colorIndex + 1) % themeColors.length];
      }
    }
    const textColor = "white";
    const fontSize = 15;
    const image = imageMapper[item.lo_wheel_odds_id];

    return {
      lo_wheel_odds_id: item.lo_wheel_odds_id,
      wheel_spoke_position: item.wheel_spoke_position,
      option: item.lo_wheel_odds_name + " " + item.lo_wheel_odds_name2,
      image: {
        uri: image,
        offsetX: 2,
        offsetY: 120,
        sizeMultiplier: array.length > 12 ? 1.13 : array.length > 6 ? 1.3 : 1.6,
      },
      style: {
        backgroundColor,
        textColor,
        fontSize,
      },
    };
  });
};
