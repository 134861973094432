import "./App.css";
import Routes from "./layout/Router/Router";

function App() {
  //for confirming the new build
  console.log("updated 12/09")
  return <Routes />;
}

export default App;
