import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import spinnerStar from "../../assets/images/spinCenter.png";
import cursorIcon from "../../assets/images/coursorimg.png";
import pointerImage from "../../assets/images/pointer.png";
import { toast } from "react-toastify";
import Border from "./Border";
import spinAudio from "../../assets/audio/wheel_rotate_sound.mp3";
import { EndPoints } from "../../services/apiEndPoints";
import { customInterceptors } from "../../services/config";
import { formatCypherText } from "../helper/FormatData";
import { errorCode } from "../../configs/ErrorMessage";
function Roullete({ setWonPrize, wonPrize, token, setShowPrize, spokes }) {
  const API = customInterceptors();
  const sound = new Audio(spinAudio);
  const [mustSpin, setMustSpin] = useState(false);
  const [cypher, setCypher] = useState(null);
  const [descryptedResult, setDecryptedResult] = useState("");
  const [disableSpinning, setDisableSpinning] = useState(false);
  // Function to handle spin button click
  const handleSpinClick = async () => {
    setDisableSpinning(true);
    try {
      if (!mustSpin && !disableSpinning) {
        const formData = new FormData();
        const decodedUrl = decodeURIComponent(token);
        formData.append("token", decodedUrl);
        const res = await API.post(EndPoints.GET_RESULT, formData);
        if (res?.data?.status === "1" && res?.data?.data) {
          setCypher(res.data.data);
          const resultToJson = formatCypherText(res.data.data);
          setDecryptedResult(resultToJson.result_id);
          const resultSpoke = spokes.find(
            (item) => item.lo_wheel_odds_id === resultToJson.lo_wheel_odds_id
          );
          const indexOfWonPrize = spokes.indexOf(resultSpoke);
          const randomNumber = indexOfWonPrize;
          setWonPrize(randomNumber);
          setMustSpin(true);
          sound.play();
        } else {
          setDisableSpinning(false);
          if (res?.data?.status === "0") {
            res?.data?.data
              ? toast.error(res.data.data)
              : toast.error(errorCode.GENERAL);
          } else {
            toast.error(errorCode.GENERAL);
          }
        }
      }
    } catch (err) {
      toast.error(errorCode.GENERAL);
      setDisableSpinning(false);
    }
  };

  // Function to handle stopping the spinning animation
  const handleStopSpinning = async () => {
    setMustSpin(false);
    sound.pause();
    const formData = new FormData();
    formData.append("token", token);
    formData.append("cypher", cypher);
    const response = await API.post(EndPoints.POST_RESULT, formData);
    if (response.data.status === "1") {
      setTimeout(() => setShowPrize(true), 1000);
      toast.info("Redirecting to lottos online", {
        position: "center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
      });
      setTimeout(
        () =>
          window.location.replace(
            `https://www.lottosonline.com/my-account/free-spins?result_id=${descryptedResult}`
          ),
        10000
      );
    } else {
      toast.error(errorCode.FORCE_REDIRECT, { closeButton: false });
      setTimeout(() => {
        window.location.replace(
          "https://www.lottosonline.com/my-account/free-spins"
        );
      }, 2000);
    }
  };

  return (
    <>
      <div className="text-2xl p-3 mt-5 px-8 text-white tracking-widest lg:tracking-[0.3em] flex justify-center lg:justify-end lg:-mb-10 place-items-center   ">
        LOTTOS ONLINE
      </div>
      <div className="overflow-hidden flex cursor-pointer">
        <div
          onClick={handleSpinClick}
          className="parent-container relative mt-2 bg-red-600"
        >
          <div className="absolute h-[100%] w-[100%]">
            <div className="grid h-[100%] w-[100%]  justify-center place-content-center">
              <img
                height={110}
                width={110}
                style={{ zIndex: 200 }}
                src={spinnerStar}
                alt="spinnerStart-I"
              />
            </div>
          </div>
          <div className="absolute h-[100%] w-[100%]">
            <Border mustSpin={mustSpin} />
          </div>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={wonPrize}
            data={spokes}
            outerBorderColor={"#ca041b"}
            radiusLineColor="none"
            innerRadius={10}
            outerBorderWidth={3}
            spinDuration={0.8}
            pointerProps={{
              src: pointerImage,
              style: {
                rotate: "-40deg",
                transform: "translateX(-25px)",
              },
            }}
            onStopSpinning={handleStopSpinning}
          ></Wheel>
        </div>
      </div>
      <div className="flex justify-end w-[95%]">
        <div className="-mt-16 xl:-mt-32 2xl:-mt-52 mr-4 2xl:mr-16 p-3">
          <img
            className="h-auto my-5 mx-auto"
            src={cursorIcon}
            alt="pointer-I"
          />
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            TAP THE
          </p>
          <p className="text-2xl lg:text-4xl font-[futura-bold] tracking-tight  text-white ">
            WHEEL TO SPIN
          </p>
        </div>
      </div>
    </>
  );
}

export default Roullete;
