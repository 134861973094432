import React from "react";
import { themeColors } from "../../configs/Colors";

const AppLayout = ({ children }) => {
  return (
    <>
      <div className="relative grid gird-cols-1">
        <div className="flex justify-center">
          <div className={`relative z-40 grid ${themeColors.primary}  text-center grid-cols-1 min-h-[85vh] w-[100vw] lg:w-[45vw] lg:shadow-lg lg:shadow-black/50 lg:border-2 border-slate-300`}>
            {children}
          </div>
        </div>

        <div className="absolute z-10  h-[100%] w-[100%]">
          <div className={`${themeColors.primary} h-1/2`}></div>
          <div className={`${themeColors.secondary} h-1/2`}></div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
