import React, { useState, useEffect } from 'react';
import lightImageMain from "../../assets/images/LightsON.png";
import lightImageSupporting from "../../assets/images/AlternateLightsON.png";

function Border({ mustSpin }) {
  const [image, setImage] = useState(lightImageMain);

  useEffect(() => {
    let intervalId;

    if (mustSpin) {
      intervalId = setInterval(() => {
        setImage((prev) => (prev === lightImageMain ? lightImageSupporting : lightImageMain));
      }, 500);
    } else {
      // Clear the interval when mustSpin becomes false to stop the animation.
      clearInterval(intervalId);
    }

    // Clean up the interval when the component unmounts.
    return () => {
      clearInterval(intervalId);
    };
  }, [mustSpin]);

  return (
    <div className="grid h-[100%] w-[100%]  justify-center place-content-center">
      <img className="spinner-background-image" src={image} alt="spinnerStart-I" />
    </div>
  );
}

export default Border;