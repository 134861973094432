import CryptoJS from "crypto-js";

export const arrangePrizeDescription = (prizeName, textMeta) => {
  const splitWords = prizeName.split(" ");

  if (textMeta === "title") {
    if (splitWords.length >= 2) return splitWords[0] + " " + splitWords[1];
  }
  if (textMeta === "subtitle") {
    if (splitWords.length > 2) {
      return splitWords.slice(2).join(" ");
    } else {
      return "";
    }
  }
};

export const formatCypherText = (encryptedData) => {
  const encryptedDataFromPHP64 = CryptoJS.enc.Base64.parse(encryptedData);
  const key = process.env.REACT_APP_HASH_KEY;
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedDataFromPHP64 },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_KEY), // UTF8 encode the IV
      mode: CryptoJS.mode.CBC, // default
      padding: CryptoJS.pad.Pkcs7, // default              // Apply PKCS7 padding
    }
  );
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
  let decryptedtext2 = CryptoJS.enc.Base64.parse(decryptedText).toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(decryptedtext2);
};
