import LandingPage from "../../../components/landing";

const UserRoutes = [
  {
    path: '/',
    component: <LandingPage/>,
    layout:"AppLayout",
    adminGuard:false
  },
]

export default UserRoutes