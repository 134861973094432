class AuthService {
  setToken = (id) => {
    localStorage.setItem("auth",id);
  };
  getToken = () => {
    const id = localStorage.getItem("auth") || "";
    return id;
  };
  clearToken = () => {
    localStorage.clear();
  };
}

const authService = new AuthService();

export default authService;
